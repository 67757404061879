import React, {useState} from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    email,
    FormTab,
    PasswordInput,
    RadioButtonGroupInput,
    ReferenceField,
    ReferenceManyField,
    required,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import {isExtended, provinces} from './Common';
import MapPicker from 'react-google-map-picker';

const validateCompare = (value, allValues) => {
    if (value !== allValues['plainPassword']) {
        return 'Hasła się nie zgadzają:';
    }
}

const passwordValidator = [validateCompare]


const Group = props => <h4>{props.children}</h4>;

const ReferenceCustomField = ({record, source}) => {
    return (
        <span style={{color: 'purple'}}>{record[source]}</span>
    )
};

const HtmlField = ({record, source}) => {
    let htmlData= record[source];
    return (
        <span dangerouslySetInnerHTML={{ __html: htmlData.replace(/\n/g, '<br />')}}></span>
    )
};

export const ContactEdit = props => {

    const [location, setLocation] = useState({lat: 52.36677086809946, lng: 19.22766944927823})

    return <Edit {...props} title={"Nowy użytkownik"}>
        <TabbedForm>
            <FormTab label="Podsumowanie">
                    <Group>Dane do logowania</Group>
                    <TextInput source="login" label={"Login"}/>
                    <PasswordInput source="plainPassword" label={"Hasło"}/>
                    <PasswordInput source="repeatPassword" label={"Powtórz hasło"} validate={passwordValidator}/>
                    <Group>Dane podstawowe</Group>
                    <TextInput source="name" label="Nazwa" validate={required()}/>
                    <TextInput source="phone" label="Telefon"/>
                    <TextInput source="city" label="Miasto"/>
                    <TextInput source="kod" label="Kod"/>
                    <TextInput source="address" label="Adres (ul. os. al.)"/>
                    <SelectInput source="locationProvince" choices={provinces} label="Województwo"/>
                    <TextInput source="email" label="E-mail" validate={[required(), email()]}/>
                    <TextInput source="www" label="Strona WWW"/>
                    <TextInput source="person" label="Osoba kontaktowa"/>
                    <TextInput source="nip" label="NIP"/>
                    <Group>Adres do korespondencji jeśli inny</Group>
                    <TextInput source="city2" label="Miasto"/>
                    <TextInput source="kod2" label="Kod"/>
                    <TextInput source="address2" label="Adres (ul. os. al.)"/>
                    <Group>Dane do faktury</Group>
                    <TextInput source="factureName" label="Nazwa"/>
                    <TextInput source="factureCity" label="Miasto"/>
                    <TextInput source="factureKod" label="Kod"/>
                    <TextInput source="factureAddress" label="Adres (ul. os. al.)"/>
                    <Group>O firmie</Group>
                    <Group>Oferta</Group>
                    <Group>Inne</Group>
                    <RadioButtonGroupInput source="extended" choices={isExtended} label="Wersja rozszerzona"/>
                    <TextInput source="latitude" value={location.lat}/>
                    <TextInput source="longitude" value={location.lng}/>
                    <MapPicker
                        apiKey='AIzaSyAJ06oFovVE54cvo0dRGnSYxABDnDH026Q'
                        onChangeLocation={(lat, lng) => setLocation({lat, lng})}
                        defaultLocation={location}
                    />
                    <TextInput multiline source="keywords" label="Słowa kluczowe"/>
                    <TextInput source="surname" label="Nazwisko"/>
                    <TextInput source="username" label="Login"/>
            </FormTab>
            <FormTab label="Historia sprzedaży">
                <Group>Historia sprzedaży</Group>

                <ReferenceManyField
                    addLabel={false}
                    reference="log/client"
                    target="client"
                    sort={{field: "created_at", order: "DESC"}}
                    sortable={false}
                >
                    <Datagrid>
                        <TextField source="id"/>
                        <DateField source="created_at" label={"Data"}/>
                        <HtmlField source="message" label={"Opis"}/>
                        <ReferenceField label="User" source="user" reference="user">
                            <TextField source="username"/>
                        </ReferenceField>

                        <ReferenceField label="Faktura" source="related_id" reference="facture/1">
                            <TextField source="number" />
                        </ReferenceField>


                    </Datagrid>
                </ReferenceManyField>

            </FormTab>

        </TabbedForm>

    </Edit>
}
