import React from 'react';
import {
    Edit,
    SimpleForm,
    DateInput,
    SelectInput,
    TextInput, ArrayInput, SimpleFormIterator, required
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const Title = ({record}) => {
    return <span>Faktura VAT nr {record ? `" ${record.number}"` : ''}</span>;
};


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


export const FactureEdit = props => {
    const classes = useStyles();

    return(
        <Edit {...props} title={<Title/>}>
            <SimpleForm>
                <DateInput source="date_of_issue" label={"Data wystawienia"} validate={[required()]}/>
                <DateInput source="date_sale" label={"Data sprzedaży"}  validate={[required()]}/>
                <TextInput source="place_of_issue" label={"Miejsce wystawienia"}  validate={[required()]}/>

              <strong>Nabywca</strong>
                {/*{props.record && props.record.props &&*/}
                {/*<ReferenceInput label="Klient" source="client_id" reference="client/new_contacts">*/}
                {/*    <SelectInput optionText="name" />*/}
                {/*</ReferenceInput>*/}
                {/*}*/}


                <TextInput source="client_name" label={"Nazwa firmy"}  validate={[required()]}/>
                <TextInput source="client_address" label={"Adres firmy"}  validate={[required()]}/>
                <TextInput source="client_nip" label={"Nip Firmy"}  validate={[required()]}/>

                <TextInput source="firm" label={"Sprzedawca"}  validate={[required()]}/>

                <ArrayInput source="items" label="Pozycje faktury"  validate={[required()]}>
                    <SimpleFormIterator>
                        <TextInput source="name"  formClassName={classes.inlineBlock} label="Nazwa"/>
                        <TextInput source="amount"  formClassName={classes.inlineBlock} label="Ilość"/>
                        <TextInput source="price" formClassName={classes.inlineBlock} label="Cena netto"/>
                        <TextInput source="vat"  formClassName={classes.inlineBlock} label="Stawka VAT" />
                    </SimpleFormIterator>
                </ArrayInput>


                <SelectInput source="form_of_payment" label="Forma zapłaty"  validate={[required()]} choices={[
                    {id: 'gotówka', name: 'Gotówka'},
                    {id: 'zapłacono przelewem', name: 'Zapłacono przelewem'},
                    {id: 'przelew', name: 'Przelew'},
                    {id: 'zapłacono gotówką', name: 'Zapłacono gotówką'},
                ]}/>


                <DateInput source="deadline_for_payment" label={"Data zapłaty"}  validate={[required()]}/>
                <TextInput source="bank_name" label={"Bank"}  validate={[required()]}/>
                <TextInput source="bank_account" label={"Numer konta"}  validate={[required()]}/>
                <TextInput source="payment_on_account" label={"Wpłacono"}/>
            </SimpleForm>
        </Edit>
    );
}
