import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

export const ProductCreate = props => (

        <Create {...props} title={"Nowa usługa"}>
            <SimpleForm>
                <TextInput source="name" label="Nazwa usługi"/>
                <TextInput source="nettoPrice" label="Cena netto" />
                <TextInput source="vat" label="Stawka VAT" />
                <TextInput source="period"  label="Okres"/>
            </SimpleForm>
        </Create>
    )
;
