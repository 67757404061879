import React from 'react';
import {BooleanField, Datagrid, EditButton, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import ClientPanel from "./ExtendedClientPanel";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id"/>
        <TextInput label="Email" source="email"/>
        <TextInput label="Telefon" source="phone" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
        <TextInput label="NIP" source="nip" alwaysOn/>
    </Filter>
);

const PublishedField = ({record, source}) => {
    let t = new Date(record[source] * 1000);
    let curr_date = t.getDate();
    let curr_month = t.getMonth() + 1; //Months are zero based
    let curr_year = t.getFullYear();
    let formatted = curr_date + "-" + curr_month + "-" + curr_year;
    let formattedTime = t.getHours() + ":" + t.getMinutes() + ":" + t.getSeconds();

    return (
        <span>
            {formatted} <br/>
            {formattedTime}
        </span>
    );
}

export const PublishedList = (props) => (
    <List  {...props} title="Wystawieni" filters={<Filters/>} filter={{status: 3}}>
        <Datagrid expand={ClientPanel}>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Nazwa"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="E-mail"/>
            <PublishedField source="time" label="Czas do końca"/>
            <BooleanField source="invoice" label="Wystawiona faktura VAT"/>
            <ReferenceField label="Opiekun" source="user" reference="user">
                <TextField source="username"/>
            </ReferenceField>
            <EditButton/>
        </Datagrid>
    </List>
);
