import React from 'react';
import {Datagrid, DeleteButton, EditButton, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" alwaysOn/>
        <TextInput label="namePl" source="namePl" alwaysOn/>
        <TextInput label="nameEn" source="nameEn" alwaysOn/>
        <TextInput label="parent" source="parent" alwaysOn/>
    </Filter>
);

export const CategoryList = (props) => (
    <List  {...props} title="Lista kategorii" filters={<Filters/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="#"/>
            <TextField source="namePl" label="Nazwa Pl"/>
            <TextField source="nameEn" label="Nazwa Ang"/>
            <ReferenceField label="Kategoria nadrzędna" source="parent" reference="category">
                <TextField source="namePl"/>
            </ReferenceField>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
)
