import React, {Fragment} from 'react';
import {NumberInput, SaveButton, SelectInput, SimpleForm, Toolbar, useDataProvider, useNotify,} from 'react-admin';
import {UpdateParams} from "ra-core";

const ClientPanelToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Zapisz"
            submitOnEnter={true}
        />

    </Toolbar>
);

const ClientPanel = ({id, record, resource}) => {
    const notify = useNotify();

    const dataProvider = useDataProvider();
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState();
    const save = (data) => {
        const updateParamsData:UpdateParams = {
            id: data.id,
            data: data,
            previousData: data
        }
        dataProvider.update('client/update-other', updateParamsData)
            .then(({data}) => {  notify(
                'Zaktualizowano',
                'info'
            );
            })
            .catch(error => {
                notify(
                    error.message,
                    'warning'
                );
            })
    };



    return (
        <Fragment>
            <SimpleForm
                record={record}
                save={save}
                toolbar={<ClientPanelToolbar/>}
            >

                <SelectInput source="publish" label="Opublikuj" choices={[
                    {id: '0', name: '---'},
                    {id: '1', name: 'rok'},
                    {id: '0.3', name: '3 miesiące'},
                ]}/>

                <NumberInput source="reminder" label="Przypomnij za"/>

            </SimpleForm>
        </Fragment>
    )
};

export default ClientPanel;
