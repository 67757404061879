import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';


const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" alwaysOn/>
        <TextInput label="Nazwa" source="namePl" alwaysOn/>
    </Filter>
);

export const PageList = (props) => (
    <List  {...props} title="Lista stron" filters={<Filters/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="#"/>
            <TextField source="namePl" label="Nazwa"/>
        </Datagrid>
    </List>
);
