// in src/posts.js
import React from 'react';
import {Create, PasswordInput, SimpleForm, TextInput} from 'react-admin';

const required = (message = 'Required') =>
    value => value ? undefined : message;

const validateCompare = (value, allValues) => {
    if (!value) {
        return 'Pole jest wymagane';
    }
    if (value !== allValues['plainPassword']) {
        return 'Hasła się nie zgadzają';
    }
    return [];
}

const passwordValidator = [required(), validateCompare]

export const UserCreate = props => (

        <Create {...props} title={"Nowy użytkownik"}>
            <SimpleForm>
                <TextInput source="name" label={"Imię"}/>
                <TextInput source="surname" label={"Nazwisko"}/>
                <TextInput source="username" label={"Login"}/>
                <TextInput type="email" source="email" label={"Email"}/>
                <PasswordInput source="plainPassword" label={"Hasło"}/>
                <PasswordInput source="repeat-password" label={"Powtórz hasło"} validate={passwordValidator}/>
            </SimpleForm>
        </Create>
    )
;
