import React from 'react';
import {Admin, fetchUtils, Resource} from 'react-admin';
import Dashboard from './Dashboard/Dashboard';
import simpleRestProvider from 'ra-data-simple-rest';
import './App.css';
import authProvider from './authProvider';
import * as config from "./config/config.js";
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ShopIcon from '@material-ui/icons/Shop';
import Menu from './Menu'
import {UserList} from './User/UserList';
import {UserEdit} from "./User/UserEdit";
import {UserCreate} from "./User/UserCreate";
import {ProductList} from './Product/ProductList';
import {ProductEdit} from "./Product/ProductEdit";
import {ProductCreate} from "./Product/ProductCreate";
import {ArticleList} from './Article/ArticleList';
import {ArticleEdit} from "./Article/ArticleEdit";
import {ArticleCreate} from "./Article/ArticleCreate";
import {PageList} from './Page/PageList';
import {PageEdit} from "./Page/PageEdit";
import {PageCreate} from "./Page/PageCreate";
import {GroupList} from "./Group/GroupList";
import {GroupEdit} from "./Group/GroupEdit";
import {SettingEdit} from "./Setting/SettingEdit";
import {CategoryList} from "./Category/CategoryList";
import {CategoryEdit} from "./Category/CategoryEdit";
import {CategoryCreate} from "./Category/CategoryCreate";
import {NewContactList} from './Crm/NewContact/NewContactList';
import {NewContactCreate} from './Crm/NewContact/NewContactCreate';
import {FactureList} from "./Facture/FactureList";
import {FactureEdit} from "./Facture/FactureEdit";
import {FactureCreate} from "./Facture/FactureCreate";
import {FactureProformaCreate} from "./FactureProforma/FactureProformaCreate";
import {FactureProformaList} from "./FactureProforma/FactureProformaList";
import {FactureProformaEdit} from "./FactureProforma/FactureProformaEdit";
import {NotInterestedList} from "./Crm/NotInterested/NotInterestedList";
import {ReminderList} from "./Crm/Reminder/ReminderList";
import {PublishedList} from "./Crm/Published/PublishedList";
import {ExpiredList} from "./Crm/Expired/ExpiredList";
import {ContactEdit} from "./Crm/ContactEdit";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('X-AUTH-TOKEN', token);
    return fetchUtils.fetchJson(url, options);
}

const apiUrl = config.API_URL;
const defaultProvider = simpleRestProvider(apiUrl, httpClient);

const customDataProvider = {
    ...defaultProvider,
    get: (resource) =>
        httpClient(`${apiUrl}/${resource}`).then(({json}) => ({
            data: json,
        })),


};


const App = () => (
//trzeba zrobic custom menu i tam wolac permissiony - przy okazji mozna przegrupowac elementy jeszzcze raz
    
    <Admin
        authProvider={authProvider}
        dataProvider={customDataProvider}
        menu={Menu}
        dashboard={Dashboard}

        title="POK - panel administracyjny"
    >

        <Resource name="client/new_contacts" options={{label: "Nowe kontakty"}} list={NewContactList} edit={ContactEdit}
                  create={NewContactCreate}/>
        <Resource name="client/reminder" options={{label: "Przypomnienia"}} list={ReminderList} edit={ContactEdit}/>
        <Resource name="client/not_interested" options={{label: "Niezainteresowani"}} list={NotInterestedList}/>
        <Resource name="client/published" options={{label: "Pozycje na portalu"}} list={PublishedList}
                  edit={ContactEdit}/>
        <Resource name="client/expired" options={{label: "Wygasłe"}} list={ExpiredList} edit={ContactEdit}/>


        <Resource name="user" options={{label: "Konta CRM"}} list={UserList} edit={UserEdit} create={UserCreate}
                  icon={PeopleIcon}/>
        <Resource name="users/group" options={{label: "Grupy kont CRM"}} list={GroupList} edit={GroupEdit}
                  icon={PeopleIcon}/>

        <Resource name="facture/1" options={{label: "Faktury VAT"}} list={FactureList} edit={FactureEdit}
                    create={FactureCreate}/>

        <Resource name="facture/2" options={{label: "Faktury Proforma"}} list={FactureProformaList}
                            edit={FactureProformaEdit} create={FactureProformaCreate}/>


        <Resource name="page" options={{label: "Strony"}} list={PageList} edit={PageEdit} create={PageCreate}
                  icon={DescriptionIcon}/>

        <Resource name="category" options={{label: "Kategorie"}} list={CategoryList} edit={CategoryEdit}
                  create={CategoryCreate}/>

        <Resource name="app_setting" options={{label: "Ustawienia"}} list={SettingEdit} edit={SettingEdit}
                  icon={SettingsIcon}/>

        <Resource name="product" options={{label: "Usługi"}} list={ProductList} edit={ProductEdit}
                  create={ProductCreate}
                  icon={ShopIcon}/>


        <Resource name="article" options={{label: "Artykuły"}} list={ArticleList} edit={ArticleEdit}
                  create={ArticleCreate}
                  icon={PostAddIcon}/>


        {/*<Resource name="log" options={{label: "Historia"}} list={LogList}  icon={PeopleIcon}/>*/}
        <Resource name="log/client"/>

    </Admin>
);
export default App;
