import React from 'react';
import {Datagrid, DateField, DateInput, EditButton, Filter, List, TextField, TextInput} from 'react-admin';
import CreateVatButton from "./CreateVatButton";
import PrintButton from "./PrintButton";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Numer faktury" source="number" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
        <TextInput label="NIP" source="client_nip" alwaysOn/>
        <DateInput label="Data sprzedaży od" source="date_of_sale_from" alwaysOn/>
        <DateInput label="Data sprzedaży do" source="date_of_sale_to" alwaysOn/>
    </Filter>
);

const ContractorAddressField = ({ record, source }) => {
    return (
        <span>
        <strong>{record[source]['name']}</strong><br/>
            {record[source]['address']} <br/><br/>
        NIP: {record[source]['nip']}
    </span>
    )};


export const FactureProformaList = (props) => (
    <List  {...props} title="Faktury Proforma" filters={<Filters/>}>
        <Datagrid>
            <TextField source="id" label="#"/>
            <TextField source="number" label="Numer faktury"/>
            <ContractorAddressField source="client" label="Klient"/>
            <DateField source="date_of_issue" label="Data wystawienia"/>
            <EditButton/>
            <PrintButton/>
            <CreateVatButton/>
        </Datagrid>
    </List>
);
