import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useUpdate, useNotify } from 'react-admin';


const CreateVatButton = ({ record }) => {
    const notify = useNotify();

    const [approve, { loading }] = useUpdate(
        'facture/generate',
        record.id,
        { status: 1  },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify(
                    'Wygenerowano fakturę VAT',
                    'info',
                    {},
                    true
                );
            },
            onFailure: () => {

                notify(
                    'Wystąpił błąd',
                    'warning'
                );

            },
        }

    );
    return (
        <Button
            color="primary"
            size="small"
            onClick={approve}
            disabled={loading}
        >
            Wystaw VAT
        </Button>
    )
};

CreateVatButton.propTypes = {
    record: PropTypes.object,
};

export default CreateVatButton;
