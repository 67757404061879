import React from 'react';
import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id"/>
        <TextInput label="Email" source="email"/>
        <TextInput label="Telefon" source="phone" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
        <TextInput label="NIP" source="nip" alwaysOn/>
    </Filter>
);

export const NotInterestedList = (props) => (
    <List  {...props} title="Niezainteresowani" filters={<Filters/>} filter={{status: 4}}>
        <Datagrid>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Nazwa"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="E-mail"/>
            <ReferenceField label="Opiekun" source="user" reference="user">
                <TextField source="username"/>
            </ReferenceField>
        </Datagrid>
    </List>
);
