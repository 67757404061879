import * as React from 'react';
import {MenuItemLink, usePermissions} from 'react-admin';
import {withRouter} from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';


const Menu = ({onMenuClick, logout}) => {
    const {permissions} = usePermissions();

    const checkPermission = (name) => {
        if (typeof permissions !== 'undefined') {
            let result = permissions.find(permission => permission === name);

            if (result !== undefined) {
                return true;
            }
            return false;
        }
    }
    return (
        <div>
            <h3>CRM</h3>
            <MenuItemLink leftIcon={<LabelIcon/>} to="/client/new_contacts" primaryText="Nowe kontakty"
                          onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<LabelIcon/>} to="/client/reminder" primaryText="Przypomnienia"
                          onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<LabelIcon/>} to="/client/not_interested" primaryText="Niezainteresowani"
                          onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<LabelIcon/>} to="/client/published" primaryText="Pozycje na portalu"
                          onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<LabelIcon/>} to="/client/expired" primaryText="Wygasłe" onClick={onMenuClick}/>
            <br/>
            <MenuItemLink leftIcon={<PeopleIcon/>} to="/user" primaryText="Konta CRM" onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<PeopleIcon/>} to="/users/group" primaryText="Grupy kont CRM"
                          onClick={onMenuClick}/>


            {checkPermission('facture.view') &&
            <MenuItemLink leftIcon={<LabelIcon/>} to="/facture/1" primaryText="Faktury VAT" onClick={onMenuClick}/>
            }
            {checkPermission('facture.view') &&
            <MenuItemLink leftIcon={<LabelIcon/>} to="/facture/2" primaryText="Faktury Proforma" onClick={onMenuClick}/>
            }

            <br/>
            <h3>CMS</h3>
            <MenuItemLink leftIcon={<DescriptionIcon/>} to="/page" primaryText="Strony" onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<DescriptionIcon/>} to="/category" primaryText="Kategorie" onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<DescriptionIcon/>} to="/app_setting" primaryText="Ustawienia"
                          onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<DescriptionIcon/>} to="/product" primaryText="Usługi" onClick={onMenuClick}/>
            <MenuItemLink leftIcon={<DescriptionIcon/>} to="/article" primaryText="Artykuły" onClick={onMenuClick}/>
            {logout}
        </div>
    );
}

export default withRouter(Menu);
