import React from 'react';
import {Datagrid, EditButton,  List, TextField} from 'react-admin';



export const GroupList = (props) => (
    <List  {...props} title="Grupy użytkowników">
        <Datagrid rowClick="edit" >
            <TextField source="id" label="#" sortable={false} />
            <TextField source="name" label="Nazwa" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);
