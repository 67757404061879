import React from 'react';
import {BooleanInput, Edit, SimpleForm, TextField, TextInput,  SelectInput, required, email} from 'react-admin';


const Title = ({record}) => {
    return <span>Użytkownik {record ? `"#${record.id} - ${record.username}"` : ''}</span>;
};


const roles = [
    {id: 'ROLE_USER', name: 'Użytkownik'},
    {id: 'ROLE_ADMIN', name: 'Admin'},
    {id: 'ROLE_ACCOUNTANT', name: 'Księgowość'},
    {id: 'ROLE_CONSULTANT', name: 'Konsultant'},
    {id: 'ROLE_ADMINISTRATION', name: 'Administracja'},
    {id: 'ROLE_DIRECTOR', name: 'Dyrektor'},
];

export const UserEdit = props => (

        <Edit {...props} title={<Title/>}>
            <SimpleForm>
                <TextField source="id" label="#"/>
                <TextInput source="username" label="login"/>
                <TextInput source="name" label="Imię"/>
                <TextInput source="surname" label="Nazwisko"/>
                <TextInput source="email" label="Email"  validate={[required(), email()]}/>
                <SelectInput source="roles" label="Grupa" choices={roles}/>
                <BooleanInput source="is_active" />
            </SimpleForm>
        </Edit>
);
