import React from 'react';
import {Datagrid, DeleteButton, EditButton, List, TextField} from 'react-admin';


export const ArticleList = (props) => (
    <List  {...props} title="Lista artykułów">
        <Datagrid>
            <TextField source="id" label="#"/>
            <TextField source="title" label="Tytuł"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);
