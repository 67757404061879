import React from 'react';
import {Edit, SimpleForm, TextField, TextInput, ReferenceInput, SelectInput} from 'react-admin';


const Title = ({record}) => {
    return <span>Kategoria {record ? `"#${record.id} - ${record.namePl}"` : ''}</span>;
};


export const CategoryEdit = props => (

        <Edit {...props} title={<Title/>}>
            <SimpleForm>
                <TextField source="id" label="#"/>
                <TextInput source="namePl" label={"Nazwa Pl"}/>
                <TextInput source="nameEn" label={"Nazwa Ang"}/>
                <ReferenceInput source="parent" label="Przypisz do kategorii" reference="category">
                    <SelectInput optionText="namePl" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
);
