// in src/posts.js
import React from 'react';
import {BooleanInput, Edit, SimpleForm, Toolbar, SaveButton} from 'react-admin';

import {Typography} from '@material-ui/core';

const Title = ({record}) => {
    return <span>Grupa {record ? `"#${record.id} - ${record.name}"` : ''}</span>;
};

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const GroupEdit = props => (

    <Edit {...props} title={<Title/>} variant="fullWidth" toolbar={<PostEditToolbar />}>
        <SimpleForm>

            <Typography variant="h6" gutterBottom fullWidth>Użytkownicy i grupy</Typography>
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.user.view_all" label="Może przeglądać wszystkich użytkowników" fullWidth />
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.user.view" label="Widzi tylko dodanych użytkowników przez siebie" fullWidth/>
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.user.edit_all" label="Może edytować wszystkich użytkowników" fullWidth/>
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.user.edit" label="Może edytować użytkowników dodanych przez siebie" fullWidth/>
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.user.create" label="Może tworzyć nowych użytkowników" fullWidth/>
            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.group.view" label="Może zarządzać grupami i uprawnieniami użytkowników" fullWidth/>

            <BooleanInput  allowEmpty={true} defaultValue={false} source="permission.facture.view" label="Może przeglądać faktury" fullWidth/>
        </SimpleForm>
    </Edit>
);
