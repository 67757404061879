import React from 'react';
import PropTypes from 'prop-types';
import * as config from "../config/config";


const PrintButton = ({ record }) => {
    const url = config.API_URL + '/facture/print/' + record['id'];
    const urlPdf = config.API_URL + '/facture/print/pdf/' + record['id'];

    return (
        <span>
        <a href={url} target="_blank" style={{'text-decoration':'none', 'text-transform':'uppercase'}}>drukuj</a>&nbsp;
        <a href={urlPdf} target="_blank" style={{'text-decoration':'none', 'text-transform':'uppercase'}}>pobierz</a>
    </span>
    )};

PrintButton.propTypes = {
    record: PropTypes.object,
};

export default PrintButton;
