// in src/posts.js
import React from 'react';
import {Edit, SimpleForm, TextField, TextInput} from 'react-admin';


const Title = ({record}) => {
    return <span>Usługa {record ? `"#${record.id} - ${record.name}"` : ''}</span>;
};


export const ProductEdit = props => (

        <Edit {...props} title={<Title/>}>
            <SimpleForm>
                <TextField source="id" label="#"/>
                <TextInput source="name" label="Nazwa usługi"/>
                <TextInput source="nettoPrice" label="Cena netto" />
                <TextInput source="vat" label="Stawka VAT" />
                <TextInput source="period" label="Okres" />
            </SimpleForm>
        </Edit>
);
