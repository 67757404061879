import React, { useState } from 'react';
import {Create, PasswordInput, SimpleForm, TextInput, SelectInput, RadioButtonGroupInput, required, email} from 'react-admin';
import {provinces, isExtended} from './Common';
import MapPicker from 'react-google-map-picker';

const validateCompare = (value, allValues) => {
    if (!value) {
        return 'Pole jest wymagane';
    }
    if (value !== allValues['plainPassword']) {
        return 'Hasła się nie zgadzają:';
    }
}

const passwordValidator = [required(), validateCompare]


const Group = props => <h4>{props.children}</h4>;



export const NewContactCreate = props => {

    const [location, setLocation] = useState({lat: 52.36677086809946, lng: 19.22766944927823})

        return <Create {...props} title={"Nowy użytkownik"}>
            <SimpleForm>
                <Group>Dane do logowania</Group>
                <TextInput source="login" label={"Login"}/>
                <PasswordInput source="plainPassword" label={"Hasło"}/>
                <PasswordInput source="repeatPassword" label={"Powtórz hasło"} validate={passwordValidator}/>
                <Group>Dane podstawowe</Group>
                <TextInput source="name" label="Nazwa" validate={required()}/>
                <TextInput source="phone" label="Telefon"/>
                <TextInput source="city" label="Miasto"/>
                <TextInput source="kod" label="Kod"/>
                <TextInput source="address" label="Adres (ul. os. al.)"/>
                <SelectInput source="locationProvince" choices={provinces} label="Województwo"/>
                <TextInput source="email" label="E-mail" validate={[required(), email()]}/>
                <TextInput source="www" label="Strona WWW"/>
                <TextInput source="person" label="Osoba kontaktowa"/>
                <TextInput source="nip" label="NIP"/>
                <Group>Adres do korespondencji jeśli inny</Group>
                <TextInput source="city2" label="Miasto"/>
                <TextInput source="kod2" label="Kod"/>
                <TextInput source="address2" label="Adres (ul. os. al.)"/>
                <Group>Dane do faktury</Group>
                <TextInput source="factureName" label="Nazwa"/>
                <TextInput source="factureCity" label="Miasto"/>
                <TextInput source="factureKod" label="Kod"/>
                <TextInput source="factureAddress" label="Adres (ul. os. al.)"/>
                <Group>O firmie</Group>
                <Group>Oferta</Group>
                <Group>Inne</Group>
                <RadioButtonGroupInput source="extended" choices={isExtended} label="Wersja rozszerzona"/>
                <TextInput source="latitude" value={location.lat}/>
                <TextInput source="longitude" value={location.lng}/>
                <MapPicker
                    apiKey='AIzaSyAJ06oFovVE54cvo0dRGnSYxABDnDH026Q'
                    onChangeLocation={(lat, lng) => setLocation({lat, lng})}
                    defaultLocation={location}
                />
                <TextInput multiline source="keywords" label="Słowa kluczowe"/>
                <TextInput source="surname" label="Nazwisko"/>
                <TextInput source="username" label="Login"/>

            </SimpleForm>
        </Create>
}
