import React from 'react';
import {Datagrid, EditButton, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import NotInterestedButton from "../NotInterestedButton";
import ClientPanel from "../ExtendedClientPanel";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" alwaysOn/>
        <TextInput label="Email" source="email" alwaysOn/>
        <TextInput label="Login" source="username" alwaysOn/>
    </Filter>
);

const ExpiredField = ({record, source}) => {

    let t = new Date(record[source] * 1000);
    var curr_date = t.getDate();
    var curr_month = t.getMonth() + 1; //Months are zero based
    var curr_year = t.getFullYear();
    let formatted = curr_date + "-" + curr_month + "-" + curr_year;
    let formattedTime =  t.getHours() + ":" + t.getMinutes() + ":" + t.getSeconds();


    return (
        <span>
            {formatted} <br/>
            {formattedTime}
        </span>
    );
}



export const ExpiredList = (props) => (
    <List  {...props} title="Wygasłe" filters={<Filters/>} filter={{ status: 6 }}>
        <Datagrid expand={<ClientPanel/>}>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Imię"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="E-mail"/>
            <ExpiredField source="time" label="Data wygaśnięcia"/>
            <ReferenceField label="Opiekun" source="user" reference="user">
                <TextField source="username"/>
            </ReferenceField>
            <EditButton/>
            <NotInterestedButton/>
        </Datagrid>
    </List>
);
