import * as React from 'react';
import {useEffect, useState} from 'react';
import {Error, Loading, SimpleForm, TextInput,  TextField, useDataProvider,showNotification} from 'react-admin';
import {UpdateParams} from "ra-core";


export const SettingEdit = ({props}) => {
    const dataProvider = useDataProvider();
    const [setting, setSetting] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const save = (data) => {
        const updateParamsData:UpdateParams = {
            id: 'setting',
            data: data,
            previousData: data
        }
        dataProvider.update('app_setting', updateParamsData)
            .then(({data}) => {
                showNotification("xxx");
            })
            .catch(error => {
                // setError(error);
            })
    };

    useEffect(() => {
        dataProvider.getOne('app_setting', {id: 'setting'})
            .then(({data}) => {
                setSetting(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!setting) return null;

    return (
        <SimpleForm
            record={setting}
            save={save}
        >
            <strong>Konfiguracja POK</strong>
            <TextInput source="pok_company_name" label="Nazwa firmy" fullWidth/>
            <TextInput source="pok_company_address" label="Nazwa firmy" fullWidth/>
            <TextInput source="pok_company_vatue" label="NIP firmy" fullWidth/>
            <TextInput source="pok_company_bank_account" label="Bank" fullWidth/>
            <TextInput source="pok_company_bank_account_name" label="Nr konta bankowego" fullWidth/>
                <TextInput source="pok_facture_number_format" label="Format numeracji faktur" fullWidth/>
            <p fullWidth>Dopuszczalne zmienne w nawiasach klamrowych:
                <ul>
                    <li><strong>number</strong> kolejny numer faktury</li>
                    <li><strong>m</strong> miesiąc wystawienia faktury</li>
                    <li><strong>y</strong> rok wystawienia faktury</li>
                </ul>
            </p>

            <TextField source="pok_next_number" label="Kolejny numer faktury" fullWidth/>

            <strong>Konfiguracja StopCalls</strong>
            <TextInput source="stopcalls_company_name" label="Nazwa firmy" fullWidth/>
            <TextInput source="stopcalls_company_address" label="Nazwa firmy" fullWidth/>
            <TextInput source="stopcalls_company_vatue" label="NIP firmy" fullWidth/>
            <TextInput source="stopcalls_company_bank_account" label="Bank" fullWidth/>
            <TextInput source="stopcalls_company_bank_account_name" label="Nr konta bankowego" fullWidth/>
            <TextInput source="stopcalls_facture_number_format" label="Format numeracji faktur" fullWidth/>
            <p fullWidth>Dopuszczalne zmienne w nawiasach klamrowych:
                <ul>
                    <li><strong>number</strong> kolejny numer faktury</li>
                    <li><strong>m</strong> miesiąc wystawienia faktury</li>
                    <li><strong>y</strong> rok wystawienia faktury</li>
                </ul>
            </p>

            <TextField source="stopcalls_next_number" label="Kolejny numer faktury" fullWidth/>
        </SimpleForm>
    )
};
