import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider } from 'react-admin';

import Welcome from './Welcome';


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Dashboard = () => {
    const [ setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchReviews = useCallback(async () => {
        try {
            const { data: reviews } = await dataProvider.getList('offer', {
                filter: { status: 2 },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            });
            const nbPendingReviews = reviews.reduce(nb => ++nb, 0);
            const pendingReviews = reviews.slice(0, Math.min(10, reviews.length));
            setState(state => ({ ...state, pendingReviews, nbPendingReviews }));
        } catch (error) {
            console.error('Error when fetching reviews', error);
        }
    }, [dataProvider]);

    const fetchActiveOffersCount = useCallback(async () => {
        try {
            const { data: offersCountResponse } = await dataProvider.get('offer/active-count');
            const activeOffersCount = offersCountResponse.count;
            setState(state => ({ ...state, activeOffersCount }));
        } catch (error) {
            console.error('Error when fetching offers', error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchReviews();
        fetchActiveOffersCount();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={styles.flex}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
        </div>
    );
};

export default Dashboard;


