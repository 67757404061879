import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const PageCreate = props => (

        <Create {...props} title={"Nowa Strona"}>
            <SimpleForm>
                <TextInput source="namePl" label="Nazwa PL"/>
                <TextInput source="nameEn" label="Nazwa EN"/>
                <RichTextInput  source="contentPl" label="Treść PL"/>
                <RichTextInput  source="contentEn" label="Treść EN"/>
            </SimpleForm>
        </Create>
    )
;
