import React from 'react';
import { Datagrid,  Filter, List, TextField, TextInput,} from 'react-admin';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
    </Filter>
);

export const ProductList = (props) => (
    <List  {...props} title="Lista usług" filters={<Filters/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="#"/>
            <TextField source="name" label="Nazwa"/>
            <TextField source="nettoPrice" label="Cena netto"/>
            <TextField source="vat" label="VAT"/>
            <TextField source="period" label="Okres"/>
        </Datagrid>
    </List>
);
