import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {  useUpdate, useNotify, useRedirect } from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const RemovePublicationButton = ({ record }) => {
    const notify = useNotify();
    const redirectTo = useRedirect();

    const [approve, { loading }] = useUpdate(
        'client/abort',
        record.id,
        { status: 0  }, //@see entity client
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify(
                    'resources.reviews.notification.approved_success',
                    'info',
                    {},
                    true
                );
                redirectTo('/client/new_contact');
            },
            onFailure: () => {
                notify(
                    'resources.reviews.notification.approved_error',
                    'warning'
                );
                redirectTo('/client/new_contact');

            },
        }

    );
    return record  ? (
        <Button
            color="primary"
            size="small"
            onClick={approve}
            disabled={loading}
        >

            Usuń publikację
        </Button>
    ) : (
        <span>Coś poszło nie tak</span>
    );
};

RemovePublicationButton.propTypes = {
    record: PropTypes.object,
};

export default RemovePublicationButton;
