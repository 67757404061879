import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput,} from 'react-admin';

const roles = [
    {id: 'ROLE_USER', name: 'Użytkownik'},
    {id: 'ROLE_ADMIN', name: 'Admin'},
    {id: 'ROLE_ACCOUNTANT', name: 'Księgowość'},
    {id: 'ROLE_CONSULTANT', name: 'Konsultant'},
    {id: 'ROLE_ADMINISTRATION', name: 'Administracja'},
    {id: 'ROLE_DIRECTOR', name: 'Dyrektor'},
];

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id" alwaysOn/>
        <TextInput label="Email" source="email" alwaysOn/>
        <TextInput label="Login" source="username" alwaysOn/>
    </Filter>
);

const getRoleName = (id) => {
    let role = roles.filter(item => item['id'] === id);
    if (typeof role !== "undefined") {
        return role[0]['name'];
    }
    return id;
}
const RolesField = ({record}) => {
    if(Array.isArray(record.roles)) {
        return (
            <span>
                {record.roles.map(item => (
                    <span key={item}>{getRoleName(item)}</span>
                ))}
            </span>
        );
    }

    return (
        <span>{getRoleName(record.roles)}</span>
    );
};
RolesField.defaultProps = {addLabel: true};

export const UserList = (props) => (
    <List  {...props} title="Lista użytkowników" filters={<Filters/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="#"/>
            <TextField source="name" label="Imię"/>
            <TextField source="surname" label="Nazwisko"/>
            <TextField source="username" label="Użytkownik"/>
            <TextField source="email" label="E-mail"/>
            <RolesField source="roles" label="Grupa/Typ" choices={roles}/>
            <BooleanField source="is_active" label="Status"/>
        </Datagrid>
    </List>
);
