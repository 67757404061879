// in src/posts.js
import React from 'react';
import {Edit, SimpleForm, TextField, TextInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const Title = ({record}) => {
    return <span>Strona {record ? `"#${record.id} - ${record.namePL}"` : ''}</span>;
};


export const PageEdit = props => (

        <Edit {...props} title={<Title/>}>
            <SimpleForm>
                <TextField source="id" label="#"/>
                <TextInput source="namePl" label="Nazwa PL"/>
                <TextInput source="nameEn" label="Nazwa EN"/>
                <RichTextInput multiline fullWidth source="contentPl" label="Treść PL"/>
                <RichTextInput multiline fullWidth source="contentEn" label="Treść EN"/>
            </SimpleForm>
        </Edit>
);
