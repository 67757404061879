import React from 'react';
import {
    ArrayInput,
    Create,
    DateInput,
    RadioButtonGroupInput,
    ReferenceInput, required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    AutocompleteInput
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '1rem'},
});
export const FactureCreate = props => {
    const classes = useStyles();

    return (
        <Create {...props} title={"Nowa faktura VAT"}>
            <SimpleForm>
                <DateInput source="date_of_issue" label={"Data wystawienia"} validate={[required()]}/>
                <DateInput source="date_sale" label={"Data sprzedaży"}  validate={[required()]}/>
                <TextInput source="place_of_issue" label={"Miejsce wystawienia"}  validate={[required()]}/>

                <strong>Nabywca</strong>
                <ReferenceInput label="Klient" source="client_id" reference="client/new_contacts">
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>


                <strong>LUB uzupełnij ręcznie</strong>
                <TextInput source="client_name" label={"Nazwa firmy"} />
                <TextInput source="client_address" label={"Adres firmy"}/>
                <TextInput source="client_nip" label={"Nip Firmy"}/>

                <RadioButtonGroupInput source="seller" label={"Sprzedawca"} choices={[
                    {id: 'pok', name: 'POK'},
                    {id: 'stopcalls', name: 'STOPCALLS'},
                ]}  validate={[required()]}/>


                <ArrayInput source="items" label="Pozycje faktury"  validate={[required()]}>
                    <SimpleFormIterator>
                        <TextInput source="name" formClassName={classes.inlineBlock} label="Nazwa"/>
                        <TextInput source="amount" formClassName={classes.inlineBlock} label="Ilość"/>
                        <TextInput source="price" formClassName={classes.inlineBlock} label="Cena netto"/>
                        <TextInput source="vat" formClassName={classes.inlineBlock} label="Stawka VAT"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <SelectInput source="form_of_payment"   validate={[required()]} label="Forma zapłaty" choices={[
                    {id: 'gotówka', name: 'Gotówka'},
                    {id: 'zapłacono przelewem', name: 'Zapłacono przelewem'},
                    {id: 'przelew', name: 'Przelew'},
                    {id: 'zapłacono gotówką', name: 'Zapłacono gotówką'},
                ]}/>

                <DateInput source="deadline_for_payment" label={"Data zapłaty"}  validate={[required()]}/>
                <TextInput source="bank_name" label={"Bank"}  validate={[required()]}/>
                <TextInput source="bank_account" label={"Numer konta"} validate={[required()]} />
                <TextInput source="payment_on_account" label={"Wpłacono"} />
            </SimpleForm>
        </Create>
    );
};
