import React from 'react';
import {Create, SimpleForm, TextInput, ReferenceInput, SelectInput} from 'react-admin';

export const CategoryCreate = props => (

        <Create {...props} title={"Nowy użytkownik"}>
            <SimpleForm>
                <TextInput source="namePl" label={"Nazwa Pl"}/>
                <TextInput source="nameEn" label={"Nazwa Ang"}/>
                <ReferenceInput source="parent" label="Przypisz do kategorii" reference="category">
                    <SelectInput optionText="namePl" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
);
