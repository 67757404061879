import React from 'react';
import {Datagrid, EditButton, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import NotInterestedButton from "../NotInterestedButton";
import ClientPanel from "../ExtendedClientPanel";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Id" source="id"/>
        <TextInput label="Email" source="email"/>
        <TextInput label="Telefon" source="phone" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
        <TextInput label="NIP" source="nip" alwaysOn/>
    </Filter>
);

const ReminderField = ({record, source}) => {
    let t = new Date(record[source] * 1000);
    let curr_date = t.getDate();
    let curr_month = t.getMonth() + 1; //Months are zero based
    let curr_year = t.getFullYear();
    let formatted = curr_date + "-" + curr_month + "-" + curr_year;
    let formattedTime = t.getHours() + ":" + t.getMinutes() + ":" + t.getSeconds();

    const now = new Date().getTime() / 1000;
    if (record[source] > now) {
        return (
            <span>
            {formatted} <br/>
            {formattedTime}
            </span>
        )
    }

    return (
        <span>
            <span style={{color: 'red'}}>Skontaktuj się natychmiast</span><br/>
            {formatted} <br/>
            {formattedTime}
        </span>
    )
}

export const ReminderList = (props) => (
    <List  {...props} title="Przypomnienia" filters={<Filters/>} filter={{status: 2}}>
        <Datagrid expand={<ClientPanel/>}>
            <TextField source="id" label="#"/>
            <TextField source="name" label="Nazwa"/>
            <TextField source="phone" label="Telefon"/>
            <TextField source="email" label="E-mail"/>
            <ReminderField source="time" label="Przypomnienie"/>
            <ReferenceField label="Opiekun" source="user" reference="user">
                <TextField source="username"/>
            </ReferenceField>
            <EditButton/>
            <NotInterestedButton/>
        </Datagrid>
    </List>
);
