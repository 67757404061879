import React from 'react';
import {BooleanField, Datagrid, DateField, DateInput, EditButton, Filter, List, TextField, TextInput,downloadCSV} from 'react-admin';
import SetPaidButton from "./SetPaidButton";
import PrintButton from "./PrintButton";
import jsonExport from 'jsonexport/dist';


const exporter = posts => {
    const postsForExport = posts.map(post => {
        const { backlinks, author, ...postForExport } = post; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        postForExport.status = post.status ? "tak" : "nie";
        return postForExport;
    });
    jsonExport(postsForExport, {
        rename: ['Id', 'Numer', "Klient", "Adres", "Nip", "Data wystawienia", "Opłacona", "Data sprzedaży", "Suma netto", "Suma  brutto", "Nazwa pozycji", "Ilość", "Kwota netto", "VAT", "Kwota brutto"]
    }, (err, csv) => {
        downloadCSV(csv, 'faktury'); // download as 'posts.csv` file
    });
};



const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Numer faktury" source="number" alwaysOn/>
        <TextInput label="Nazwa" source="name" alwaysOn/>
        <TextInput label="NIP" source="client_nip" alwaysOn/>
        <DateInput label="Data sprzedaży od" source="date_of_sale_from" alwaysOn/>
        <DateInput label="Data sprzedaży do" source="date_of_sale_to" alwaysOn/>
    </Filter>
);

const ContractorAddressField = ({record, source}) => {
    return (
        <span>
        <strong>{record[source]['name']}</strong><br/>
            {record[source]['address']} <br/><br/>
        NIP: {record[source]['nip']}
    </span>
    )
};

export const FactureList = (props) => (
    <List  {...props} title="Faktury VAT" filters={<Filters/>} exporter={exporter}>
        <Datagrid>
            <TextField source="id" label="#"/>
            <TextField source="number" label="Numer"/>
            <ContractorAddressField source="client" label="Klient"/>
            <DateField source="date_of_issue" label="Data wystawienia"/>
            <BooleanField source="status" label="Opłacone"/>
            <PrintButton />
            <EditButton/>
            <SetPaidButton/>
        </Datagrid>
    </List>
);
