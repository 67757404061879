import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import * as config from "./config/config";

const loginUrl = config.API_URL + '/user/security/sign-in';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(loginUrl, {
            method: 'POST',
            body: JSON.stringify({ email: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-AUTH-LOGIN': username, 'X-AUTH-PASSWORD': password }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                console.log(token);
                localStorage.setItem('token', token['token']);
                localStorage.setItem('permissions', token['permissions']);
                console.log(token['permissions'])
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    if(type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('permissions');

        return role ? Promise.resolve(role.split(',')) : Promise.reject();
    }

    return Promise.resolve();
}
