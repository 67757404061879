import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const ArticleCreate = props => (

        <Create {...props} title={"Nowy artykuł"}>
            <SimpleForm>
                <TextInput source="title" label="Tytuł"/>
                <TextInput multiline source="contentShort" label="Streszczenie" fullWidth/>
                <RichTextInput multiline source="content" label="Treść" fullWidth/>
            </SimpleForm>
        </Create>
    )
;
