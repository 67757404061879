import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify,  useUpdate} from 'react-admin';


const SetPaidButton = ({ record }) => {
    const notify = useNotify();

    const [approve, { loading }] = useUpdate(
        'facture/paid',
        record.id,
        { status: 1  },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify(
                    'Oznaczono jako opłaconą',
                    'info',
                    {},
                    true
                );
            },
            onFailure: () => {
                notify(
                    'Wystąpił błąd - spróbuj ponownie później',
                    'warning'
                );
            },
        }

    );
    return record.status !== 1  ? (
        <Button
            color="primary"
            size="small"
            onClick={approve}
            disabled={loading}
        >
            Ustaw opłacone
        </Button>
    ): (<span></span>);
};

SetPaidButton.propTypes = {
    record: PropTypes.object,
};

export default SetPaidButton;
