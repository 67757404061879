import React from 'react';
import {Edit, SimpleForm, TextField, TextInput} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

const Title = ({record}) => {
    return <span>Artykuł {record ? `"#${record.id} - ${record.title}"` : ''}</span>;
};


export const ArticleEdit = props => (

        <Edit {...props} title={<Title/>}>
            <SimpleForm>
            <TextField source="id" label="#"/>
            <TextInput source="title" label="Tytuł"/>
            <TextInput multiline source="contentShort" label="Streszczenie" fullWidth/>
            <RichTextInput multiline source="content" label="Treść" fullWidth/>
            </SimpleForm>
        </Edit>
);
